import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  Popover,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import { PrintOutlined } from '@mui/icons-material'
import {camelCase, find, flatMapDeep, orderBy, split, startCase, trim, upperFirst} from 'lodash'

import {ComboCuppingFlag, FormInput, HeaderTitle, ModalWrapper} from 'components'
import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share"
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import { isMobile } from "react-device-detect"
import { colors } from 'assets'

import { useStyles } from './sample-report-collective.styles'
import {validateEmail, flavorWheel, printPage, optionsTranslation} from 'utils'
import { scaDescriptorList } from 'constants/sca-descriptors'
import {IconFloverWheelEmpaty, IconStartCupping, Logo} from 'assets/images'
import {allDescriptor} from "constants/all-descriptors";
import {sucafinaTastingForm} from "constants/form";

export const SampleReportCollective: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const params = useParams()
  const sampleLink = findRoute('sampleReview')
  const sampleInformationLink = findRoute('sampleInformation')
  const sampleReviewLink = findRoute('sampleReview')
  const printElement = React.createRef<Element | undefined>()

  const {
      sampleReportStore: { title, emails, message, sendReportInvitation, setValue: setSampleReportValue },
      userStore: { isAuthenticated, languagePreference },
      companyStore: { base64Logo, isEnterprisePlus },
      notificationStore: { setLoading, setNotification },
      collectiveReportStore: { getCollectiveReport, collectiveReports, isComboCupping }
  } = useStores()

  const [anchorShare, setAnchorShare] = React.useState<HTMLButtonElement | null>(null)
  const openShare = Boolean(anchorShare)
  const [openShareEmail, setOpenShareEmail] = React.useState(false)
  const [isSendingEmail, setIsSendingEmail] = React.useState(false)
  const [isEmailError, setIsEmailError] = React.useState(false)
  const [emailTextHelper, setEmailTextHelper] = React.useState(t('report.emailShareSampleDescription'))
  const [isPrinting, setIsPrinting] = React.useState(false)
  const [dataCheckbox] = React.useState<any[]>([])
  const [checkedFragranceWheel, setCheckedFragranceWheel] = React.useState({});
  const [checkedFlavorWheel, setCheckedFlavorWheel] = React.useState({});
  const [checkedTopWheel, setCheckedTopWheel] = React.useState({});

  const reportUrl = window.location.href

  const isScaDescriptive = (item) => item.cuppingProtocol === 'sca_descriptive'
  const isSucafinaTasting = (item) => item.cuppingProtocol === 'sucafina_tasting'

  const renderTitle = (
    <Stack direction="row" spacing={1}>
      <Typography variant='h5'>
        {t('report.collectiveReport')}
      </Typography>
      {isComboCupping && <ComboCuppingFlag />}
    </Stack>
  )

  const pickTextColorBasedOnBgColorSimple = (bgColor) => {
    if(bgColor === undefined) return

    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      colors.text.primary : 'white';
  }

  const renderEmoticon = (value: number) => {
    const emo = find(sucafinaTastingForm, ['value', value])
    if (!emo) return null

    return (
        <emo.emoticon width={24} height={24} />
    )
  }

  const shareByEmail = async () => {
    let isCorrectEmails = true
    setIsEmailError(false)
    setEmailTextHelper(t('report.emailShareSampleDescription'))

    split(emails, ',').forEach(email => { if (!validateEmail(trim(email))) isCorrectEmails = false })

    if (!isCorrectEmails) {
      setIsEmailError(true)
      setEmailTextHelper(t('report.error.emailIsInvalid'))
      return
    }

    setSampleReportValue('emails', emails.replace(/\s/g, ""))
    setIsSendingEmail(true)

    try {
      const res = await sendReportInvitation(params.id)
      res.base?.map(message => setNotification(message))

      setOpenShareEmail(false)
      setAnchorShare(null)
    } finally {
      setIsSendingEmail(false)
    }
  }

  const renderSucafinaTastingForm = (elem) => {
    return(
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              {elem.descriptors.map(data => renderDescription(elem.cuppingProtocol, data))}
            </Grid>
          </Grid>
          <Grid  item xs={12}>
            <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap"  sx={{p : 3, justifyContent: 'center'}}>
              {elem.emoticons.map(emoticon => renderEmoticon(emoticon))}
            </Stack>

          </Grid>
        </Grid>
    )
  }

  const renderEmailModal = (
    <ModalWrapper
      maxWidth='sm'
      open={openShareEmail}
      onClose={() => setOpenShareEmail(false)}
    >
      <Typography variant='h5'>{t('report.shareReport')}</Typography>
      <FormInput
        label={t('report.to')}
        textFieldProps={{
          error: isEmailError,
          value: emails,
          onChange: (e) => setSampleReportValue('emails', e.target.value),
          helperText: emailTextHelper
        }}
      />
      <FormInput
        label={t('report.emailMessage')}
        textFieldProps={{
          multiline: true,
          minRows: 5,
          value: message,
          onChange: (e) => setSampleReportValue('message', e.target.value),
        }}
      />

      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Button
          variant='outlined'
          onClick={() => setOpenShareEmail(false)}
          className='wide'
          sx={{ mr: 2 }}
        >
          {t('common.cancel')}
        </Button>
        <LoadingButton
          variant='contained'
          onClick={shareByEmail}
          className='wide'
          disabled={!emails && !message}
          loading={isSendingEmail}
        >
          {t('report.send')}
        </LoadingButton>
      </Box>
    </ModalWrapper>
  )

  const renderButtons = (
    <Stack direction='row' spacing={1} >
      {(isAuthenticated && !isMobile) &&
        <>
          <Button
            size='small'
            variant='contained'
            sx={{ ml: 1 }}
            onClick={(event) => setAnchorShare(event.currentTarget)}
          >
            {t('report.share')}
          </Button>
          <Popover
            open={openShare}
            anchorEl={anchorShare}
            onClose={() => setAnchorShare(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: { pt: 1, px: 2 }
            }}
          >
            <FacebookShareButton url={reportUrl} className={classes.socialIcon}>
              <FacebookIcon size={24} round />
            </FacebookShareButton>
            <LinkedinShareButton url={reportUrl} className={classes.socialIcon}>
              <LinkedinIcon size={24} round />
            </LinkedinShareButton>
            <TwitterShareButton url={reportUrl} className={classes.socialIcon}>
              <TwitterIcon size={24} round />
            </TwitterShareButton>
            <WhatsappShareButton title={message} url={reportUrl} className={classes.socialIcon}>
              <WhatsappIcon size={24} round />
            </WhatsappShareButton>
          </Popover>
        </>
      }
      {(!isMobile && isAuthenticated) &&
        <>
          <Button
            size='small'
            variant='contained'
            sx={{ ml: 1 }}
            onClick={() => setIsPrinting(true)}
          >
            {t('report.print')}
          </Button>
        </>
      }
    </Stack>
  )

  const getDescriptor = (descriptor) => {
    if (!descriptor.groups || !descriptor.groups.length) return descriptor

    return [descriptor, flatMapDeep(descriptor.groups, getDescriptor)]
  }

  const descriptorTranslation = (language, data) => {
    const flatList = flatMapDeep(scaDescriptorList, getDescriptor)
    const label = `label_${language}`

    if (language === 'en') return upperFirst(data)

    return upperFirst(flatList.filter(x => upperFirst(x.label) === upperFirst(data))[0][label])
  }

  const descriptorColor = (cuppingProtocol, data) => {
    const flatList = flatMapDeep(cuppingProtocol.includes("sca") ? scaDescriptorList : allDescriptor, getDescriptor)
    if (flatList.findIndex(x => upperFirst(x.label) === upperFirst(data)) >= 0) {
      return flatList.filter(x => upperFirst(x.label) === upperFirst(data))[0]['color']
    }
  }

  const renderDescription = (cuppingProtocol, data) => {
    return (
      <Chip
        key={data.label}
        size="small"
        variant='outlined'
        sx={{
          mr: 1,
          mt: 1,
          backgroundColor: descriptorColor(cuppingProtocol, data.label),
          color: pickTextColorBasedOnBgColorSimple(descriptorColor(cuppingProtocol, data.label)),
          borderColor: descriptorColor(cuppingProtocol, data.label)
        }}
        label={`${descriptorTranslation(languagePreference, data.label)} (${data.qty})`}
      />
    )
  }

  const poweredImage = (absolute = false) => {
    return (
      <Box className={clsx(classes.powered, absolute && 'absolute')}>
        <Typography variant='body3' sx={{mr: 0.5}}>{t('report.poweredBy')}</Typography>
        <Logo width={50} height={25} />
      </Box>
    )
  }

  const renderShareButtonMobileVersion = (
    <Box justifyContent="flex-start" sx={{ flexDirection: 'row', display: 'flex' }}>
      <Box><Typography>{t('sample.share')} :</Typography></Box>
      <Box sx={{mt: -1}}>
        <FacebookShareButton url={reportUrl} className={classes.socialIcon}>
          <FacebookIcon size={24} round />
        </FacebookShareButton>
        <LinkedinShareButton url={reportUrl} className={classes.socialIcon}>
          <LinkedinIcon size={24} round />
        </LinkedinShareButton>
        <TwitterShareButton url={reportUrl} className={classes.socialIcon}>
          <TwitterIcon size={24} round />
        </TwitterShareButton>
        <WhatsappShareButton title={message} url={reportUrl} className={classes.socialIcon}>
          <WhatsappIcon size={24} round />
        </WhatsappShareButton>
      </Box>
    </Box>
  )

  const renderButtonMobileVersion = (
    <AppBar  position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white'}}>
      <Grid container spacing={1} sx={{p: 2}}>

        <Grid item xs={12}>
          {isAuthenticated &&
            <Button
            fullWidth
            size='small'
            disabled= {!isAuthenticated}
            variant='contained'
            onClick={() => setIsPrinting(true)}
          >
            <SvgIcon component={PrintOutlined} inheritViewBox sx={{ mr: 1, width: 20, height: 25 }} /><Typography variant='h6'>{t('report.print')}</Typography>
          </Button>
          }

        </Grid>
      </Grid>
    </AppBar>
  )

  const renderInfoItem = (label: string, value: string | number) => {
    return (
      <Box display="flex" alignItems="end" justifyContent="space-between" mb={0.5}>
        <Typography variant="body2" style={{wordBreak: 'break-word'}}>{label}</Typography>
        <Typography variant="subtitle1">{value}</Typography>
      </Box>
    )
  }

  const renderScore = (cuppingProtocol, jsonScores) => {
    const scores = JSON.parse(jsonScores)

    let title = ''
    switch (cuppingProtocol) {
      case 'sca':
      case 'sca_descriptive':
        title = 'report.avgIntensities'
        break
      case 'sca_affective':
        title = 'report.avgScore'
        break
      default:
        break
    }

    return (
      <Box className={classes.infoWrapper} width="55%">
        {title && <Typography variant='subtitle1' mb={1}>{t(title)}</Typography>}

        {Object.keys(scores).map(key => { return renderInfoItem(startCase(key), scores[key]) })}
      </Box>
    )
  }

  const renderDefect = (cuppingProtocol, defects) => {
    const title = 'descriptor.defects'

    return (
      <Box className={classes.infoWrapper} width="40%">
        {title && <Typography variant='subtitle1' mb={1}>{t(title)}</Typography>}
        {defects.length === 0
          ? <Typography variant="body2">{t('report.noneFound')}</Typography>
          : <>{defects.map(defect => renderInfoItem(startCase(defect.label), `(${defect.qty})`))}</>
        }
      </Box>
    )
  }

  const setFlavorWheel = () => {
    if (isPrinting) return

    orderBy(collectiveReports, "id", "asc").forEach((data, index)=> {
      flavorWheel(JSON.parse(data.flavorWheelGroups), isEnterprisePlus && base64Logo, `tastify-wheel-${index}`)

      setCheckedFragranceWheel((values) => ({
        ...values,
        [index]: true
      }));

      setCheckedFlavorWheel((values) => ({
        ...values,
        [index]: true
      }));
    })
  }

  const setDataCheckbox = (index = -1) => {
    if (isPrinting) return

    if(index === -1){
      dataCheckbox.length = 0
      orderBy(collectiveReports, "id", "asc").forEach(data => {
        dataCheckbox.push({id: data.id, flavorWheel: JSON.parse(data.flavorWheelGroups),isFlavorWheelOnlyFragrance: true, isFlavorWheelOnlyFlavor : true, isTopWheel: false })
      })
      return
    }

    const dataIndex = dataCheckbox[index]
    const collectiveReport = collectiveReports[collectiveReports.findIndex( x => x.id === dataIndex.id)]
    const isTopWheel = dataIndex.isTopWheel
    let flavorWheelGroups = []

    if (dataIndex.isFlavorWheelOnlyFragrance && dataIndex.isFlavorWheelOnlyFlavor){
      flavorWheelGroups = JSON.parse(collectiveReport[isTopWheel ? 'topFlavorWheelGroups' : 'flavorWheelGroups'])
    }

    if (dataIndex.isFlavorWheelOnlyFragrance && !dataIndex.isFlavorWheelOnlyFlavor) {
      flavorWheelGroups = JSON.parse(collectiveReport[isTopWheel ? 'topFlavorWheelOnlyFragrance' : 'flavorWheelOnlyFragrance'])
    }

    if (!dataIndex.isFlavorWheelOnlyFragrance && dataIndex.isFlavorWheelOnlyFlavor) {
      flavorWheelGroups = JSON.parse(collectiveReport[isTopWheel ? 'topFlavorWheelOnlyFlavor' : 'flavorWheelOnlyFlavor'])
    }

    dataIndex.flavorWheel = flavorWheelGroups
    flavorWheel(flavorWheelGroups, isEnterprisePlus && base64Logo, `tastify-wheel-${index}`)
  }

  const handleCheckedFragranceWheel = (id, e) => {
    const { checked } = e.target;
      setCheckedFragranceWheel((values) => ({
        ...values,
        [id]: checked
      }));
      dataCheckbox[id].isFlavorWheelOnlyFlavor = checked

      setDataCheckbox(id)
  };

  const handleCheckedFlavorWheel = (id, e) => {
    const { checked } = e.target;
      setCheckedFlavorWheel((values) => ({
        ...values,
        [id]: checked
      }));
      dataCheckbox[id].isFlavorWheelOnlyFragrance = checked
      setDataCheckbox(id)
  };

  const handleCheckedTopWheel = (id, e) => {
    const { checked } = e.target;
    setCheckedTopWheel((values) => ({
      ...values,
      [id]: checked
    }));
    dataCheckbox[id].isTopWheel = checked
    setDataCheckbox(id)
  };

  const loadData = async (firstLoad = false) => {
    if (!isAuthenticated && !firstLoad) return

    await getCollectiveReport(params.id)
    setFlavorWheel()
    setDataCheckbox()
  }

  React.useEffect(() => {
    if (!isPrinting || !printElement.current) return

    (async () => {
      try {
        setLoading(true)
        await printPage(printElement.current,true)
      } finally {
        setIsPrinting(false)
        setLoading(false)
      }
    })()
  }, [isPrinting])

  React.useEffect(() => {
    if (!params.id) return

    loadData(true)
    const interval = setInterval(loadData, 60000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <HeaderTitle
          title=""
          subtitle={renderTitle}
          rightContent={renderButtons}
          {...(isAuthenticated && {
            dataLinks: [
              {
                backUrl: sampleLink ,
                breadcrumb: t('common.backTo', { menu: t('menu.reviewSamples')}),
              },
              {
                backUrl: sampleInformationLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleInformation')}),
              },
              {
                backUrl: sampleReviewLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleReview')}),
              }
            ]
          })}
          sx={{maxWidth: "100% !important", mx: 0, p: '1 !important'}}
        />
        <Box ref={printElement} data-name='report-collective' sx={{mt: (isMobile && !isPrinting) ? -5 : 0}}>
        {isPrinting &&
          <Container sx={{mb: 3}}>
            <Typography variant='h4'>{title}</Typography>
          </Container>
        }
        <Box sx={{mb: 2, p: 1}}>
          {(isMobile && isAuthenticated) && !isPrinting && renderShareButtonMobileVersion}
          <div className={classes.gridReport}>
              <Grid
                container
                spacing={2}
                direction="row"
                marginBottom={1}
              >
              {orderBy(collectiveReports,"id", "asc").map((elem, i) => (
                  <Grid item xs={isMobile ? 12:6} key={i}>
                  <Card sx={{minHeight: '100%'}}>
                    <Box sx={{minHeight: elem.cuppingProtocol.includes("sca") ? 420: 350, display: 'flex', flexDirection: 'column'}}>
                      <Box sx={{p: 2}}>
                        <Stack direction="row" spacing={0.5}>
                          <Typography variant='h5' sx={{fontWeight: '600'}} color={colors.primary.main}>{elem.sampleName}</Typography>
                          <Typography variant='h5' sx={{fontWeight: '400'}} color={colors.primary.main}>({elem.sampleUniqueNumber})</Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.5}>
                          <Typography variant="body2">{t('report.cuppingProtocol')}</Typography>
                          <Typography variant="body2" sx={{color: colors.primary.main, fontWeight: '600'}}>
                            {optionsTranslation('cuppingProtocol', elem.cuppingProtocol)}
                          </Typography>
                        </Stack>
                      </Box>
                      <Box sx={{ minHeight: '350', flexDirection: isMobile ? 'column' : 'row', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        <Box sx={{ width: '100%' }}>
                          <Box sx={{ mr: isMobile ? 3 : 0, p: 1 }}>
                            <Stack direction="row" spacing={0.5} className={classes.totalCupper}>
                              <IconStartCupping />
                              <Typography variant='body1'>{t('report.totalCupper')}</Typography>
                              <Typography variant='body1'>{elem.totalCupper}</Typography>
                            </Stack>

                            <Typography variant='h5' >{t('report.averageScore')}</Typography>
                            <Typography
                              variant={isScaDescriptive(elem) ? 'h3' : 'h2'}
                              color={isScaDescriptive(elem) ? colors.text.o50 :colors.primary.main}
                            >
                              {!isScaDescriptive(elem) ? elem.average : 'N/A'}
                            </Typography>
                            {!isScaDescriptive(elem) &&
                              <Typography variant='h4' sx={{fontWeight: '400'}} color={colors.primary.o50}>
                                ({elem.range})
                              </Typography>
                            }
                            {isSucafinaTasting(elem) && renderSucafinaTastingForm(elem)}
                          </Box>
                          <Box p={2} display="flex" justifyContent="space-between">
                            {elem.cuppingProtocol.includes("sca") &&  renderScore(elem.cuppingProtocol, elem.scores)}
                            {elem.cuppingProtocol.includes("sca") &&  renderDefect(elem.cuppingProtocol, elem.defects)}
                          </Box>
                        </Box>
                        <Box sx={{width: '100%'}}>
                          <Box className={classes.wheelWrapper}>
                            {isSucafinaTasting(elem) &&
                              <Box>
                                {JSON.parse(elem.interestStatus).map(item =>
                                  <Button
                                    size="small"
                                    variant='outlined'
                                    sx={{ml : 1}}
                                  >
                                    {`${item.label === "true" ? t('report.boolean.true'): t('report.boolean.false')} (${item.qty})`}
                                  </Button>
                                )}
                              </Box>
                            }
                            <Card className={classes.headerCardWheel}>
                              {JSON.parse(elem.flavorWheelGroups).length !== 0 ?
                              <div id={`tastify-wheel-${i}`}  className={classes.wheel}></div> :
                              <SvgIcon component={IconFloverWheelEmpaty} inheritViewBox sx={{ mr: 1, width: 240, height: 240 }} />}
                            </Card>

                            {poweredImage()}
                            {elem.cuppingProtocol.includes("sca") &&
                                <>
                                  <Box sx={{textAlign: 'center', flexDirection: 'row', p: 2}}>
                                    <FormControlLabel
                                        label={`${t('descriptor.fragranceOrAroma')}`}
                                        className={classes.checkbox}
                                        control={
                                          <Checkbox
                                              size="small"
                                              defaultChecked={true}
                                              disabled={!checkedFragranceWheel[i] || JSON.parse(elem.flavorWheelGroups).length === 0 }
                                              checked={checkedFlavorWheel[elem.id]}
                                              onChange={(e) => {
                                                handleCheckedFlavorWheel(i,e)
                                              }}
                                          />
                                        }
                                    />

                                    <FormControlLabel
                                        label={`${t('descriptor.flavorOrAftertaste')}`}
                                        className={classes.checkbox}
                                        control={
                                          <Checkbox
                                              size="small"
                                              defaultChecked={true}
                                              disabled={!checkedFlavorWheel[i] || JSON.parse(elem.flavorWheelGroups).length === 0}
                                              checked={checkedFragranceWheel[elem.id]}
                                              onChange={(e) => {
                                                handleCheckedFragranceWheel(i,e)
                                              }}
                                          />
                                        }
                                    />

                                  </Box>
                                  <Box textAlign="center">
                                    <FormControlLabel
                                        label={`${t('descriptor.topTenFlavorWheel')}`}
                                        className={classes.checkbox}
                                        control={
                                          <Checkbox
                                              size="small"
                                              disabled={JSON.parse(elem.flavorWheelGroups).length === 0}
                                              checked={checkedTopWheel[elem.id]}
                                              onChange={(e) => {
                                                handleCheckedTopWheel(i,e)
                                              }}
                                          />
                                        }
                                    />
                                  </Box>
                                </>
                            }

                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Card>

                  {(isPrinting && (i + 1) % 1 === 0 && isMobile) &&  <div className="html2pdf__page-break" />}
                  {(isPrinting && (i + 1) % 6 === 0 && !isMobile) &&  <div className="html2pdf__page-break" />}
                </Grid>
              ))}
              </Grid>
          </div>
          </Box>
        </Box>
        <Box sx={{mt: 7}}>
          {isMobile && !isPrinting && !(window as any).isRNWebview && renderButtonMobileVersion}
        </Box>

      {renderEmailModal}
    </>
  )
})

export default SampleReportCollective
